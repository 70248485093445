import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  type RouteObject
} from 'react-router-dom';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useSessionHelpers } from '@/hooks/helpers/useSessionHelpers';
import { getApplicationBasePath, getBuilderBaseUrl } from '@/utils/application';
import { FEATURE_FLAGS, isFlagEnabled } from '@/utils/flagsmith';
import { EditRecordModal } from '@/components/data-table/display/edit-record/EditRecordModal';
import { ResetPasswordSubPage } from '@/components/data-table/display/user-roles/ResetPassword';
import { SendAccountInfoSubPage } from '@/components/data-table/display/user-roles/SendAccountInfoSubPage';
import { SendWelcomeSubPage } from '@/components/data-table/display/user-roles/SendWelcomeSubPage';
import { RouterErrorBoundary } from '@/components/errors/RouterErrorBoundary';
import { AddTablePage } from '@/pages/add-table/AddTablePage';
import { DataModelPage } from '@/pages/data-model/DataModelPage';
import { FlowsList } from '@/pages/flows/flows-list/FlowsList';
import { FlowsPage } from '@/pages/flows/FlowsPage';
import { ImportRecordsPage } from '@/pages/import-records/ImportRecordsPage';
import { NotFoundPage } from '@/pages/not-found/NotFoundPage';
import { PageEditor } from '@/pages/pages/page-editor/PageEditor';
import { PagesPage } from '@/pages/pages/PagesPage';
import { CopyRoleDialog } from '@/pages/roles/copy-role/CopyRole';
import { DeleteRoleDialog } from '@/pages/roles/delete-role/DeleteRole';
import { RoleSettingsDialog } from '@/pages/roles/role-settings/RoleSettings';
import { RolesPage } from '@/pages/roles/RolesPage';
import { RolesPageContent } from '@/pages/roles/RolesPageContent';
import { SettingsPage } from '@/pages/settings/SettingsPage';
import { TablesPage } from '@/pages/tables/TablesPage';
import { TablesPageContent } from '@/pages/tables/TablesPageContent';
import { ThemeEditor } from '@/pages/themes/theme-editor/ThemeEditor';
import { ThemesList } from '@/pages/themes/themes-list/ThemesList';
import { ThemesPage } from '@/pages/themes/ThemesPage';

type ProtectedRouteProps = {
  children?: JSX.Element;
  flagProtection?: keyof typeof FEATURE_FLAGS;
  allowSharedBuilder?: boolean;
};

export type PageUrlParams = {
  id: string;
};

export enum ROUTES {
  ROOT = '/',
  TABLES = '/tables',
  TABLES_ADD = '/tables/add',
  TABLES_ID = '/tables/:id',
  TABLES_ID_ADD = '/tables/:id/add',
  TABLES_ID_IMPORT = '/tables/:id/import',
  TABLES_ID_RECORD_ID_EDIT = '/tables/:id/record/:recordId/edit',
  FLOWS = '/flows',
  DATA_MODEL = '/data-model',
  PAGES = '/pages',
  PAGES_ID = '/pages/:id',
  THEMES = '/themes',
  THEMES_ID = '/themes/:id',
  ROLES = '/roles',
  ROLES_ID = '/roles/:id',
  ROLES_ID_RECORD_ID_EDIT = '/roles/:id/record/:recordId/edit',
  ROLES_ID_SETTINGS_SETTING_ID = '/roles/:id/settings/:selectedTableKey/:settingId',
  ROLES_ID_SEND_ACCOUNT_INFO = '/roles/:id/action/send-account-info/:rowId',
  ROLES_ID_RESET_PASSWORD = '/roles/:id/action/reset-password/:rowId',
  ROLES_ID_SEND_WELCOME_EMAIL = '/roles/:id/action/send-welcome-email/:rowId',
  ROLES_ID_COPY = '/roles/:id/copy',
  ROLES_ID_DELETE = '/roles/:id/delete',
  PAYMENTS = '/payments',
  SETTINGS = '/settings',
  CATCH_ALL = '*'
}

function ProtectedRoute({
  children,
  flagProtection,
  allowSharedBuilder = true
}: ProtectedRouteProps) {
  const { data: application } = useApplicationQuery();
  const { isSharedBuilder } = useSessionHelpers();

  if (!allowSharedBuilder && isSharedBuilder()) {
    return <Navigate to={ROUTES.ROOT} replace />;
  }

  // We give priority to the flags, so if the route has flag protection and the flag is enabled, we can continue
  if (flagProtection && isFlagEnabled(flagProtection)) {
    return children || <Outlet />;
  }

  // Next, check if the user has access to non-public features
  const hasAccessToNonPublicFeatures =
    import.meta.env.DEV || !!application?.account.isInternalAccount;
  if (hasAccessToNonPublicFeatures) {
    return children || <Outlet />;
  }

  // Otherwise, redirect to the v3 builder
  window.location.replace(getBuilderBaseUrl());
  return null;
}

export const routeList: RouteObject[] = [
  {
    element: <RouterErrorBoundary />,
    children: [
      {
        path: ROUTES.ROOT,
        element: <Navigate to={ROUTES.TABLES} replace />
      },
      {
        path: ROUTES.TABLES_ADD,
        element: <AddTablePage />
      },
      {
        path: ROUTES.TABLES_ID_IMPORT,
        element: <ImportRecordsPage />
      },
      {
        element: (
          <ProtectedRoute flagProtection={FEATURE_FLAGS.albato_flows} allowSharedBuilder={false} />
        ),
        children: [
          {
            path: ROUTES.FLOWS,
            element: <FlowsPage />,
            children: [
              {
                index: true,
                element: <FlowsList />
              }
            ]
          }
        ]
      },
      {
        element: <ProtectedRoute flagProtection={FEATURE_FLAGS.page_editor} />,
        children: [
          {
            path: ROUTES.PAGES,
            element: <PagesPage />,
            children: [
              {
                index: true,
                element: <PageEditor isIndex />
              },
              {
                path: ROUTES.PAGES_ID,
                element: <PageEditor />
              }
            ]
          }
        ]
      },
      {
        element: <ProtectedRoute flagProtection={FEATURE_FLAGS.themes} />,
        children: [
          {
            path: ROUTES.THEMES,
            element: <ThemesPage />,
            children: [
              {
                index: true,
                element: <ThemesList />
              },
              {
                path: ROUTES.THEMES_ID,
                element: <ThemeEditor />
              }
            ]
          }
        ]
      },
      {
        element: <ProtectedRoute flagProtection={FEATURE_FLAGS.data_table_v1} />,
        children: [
          {
            path: ROUTES.TABLES,
            element: <TablesPage />,
            children: [
              {
                index: true,
                element: <TablesPageContent isIndex />
              },
              {
                path: ROUTES.TABLES_ID,
                element: <TablesPageContent />,
                children: [
                  {
                    path: ROUTES.TABLES_ID_RECORD_ID_EDIT,
                    element: <EditRecordModal />
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        element: <ProtectedRoute flagProtection={FEATURE_FLAGS.data_table_v1} />,
        children: [
          {
            path: ROUTES.ROLES,
            element: <RolesPage />,
            children: [
              {
                index: true,
                element: <RolesPageContent isIndex />
              },
              {
                path: ROUTES.ROLES_ID,
                element: <RolesPageContent />,
                children: [
                  {
                    path: ROUTES.ROLES_ID_SETTINGS_SETTING_ID,
                    element: <RoleSettingsDialog />
                  },
                  {
                    path: ROUTES.ROLES_ID_COPY,
                    element: <CopyRoleDialog />
                  },
                  {
                    path: ROUTES.ROLES_ID_DELETE,
                    element: <DeleteRoleDialog />
                  },
                  {
                    path: ROUTES.ROLES_ID_RECORD_ID_EDIT,
                    element: <EditRecordModal />
                  },
                  {
                    path: ROUTES.ROLES_ID_SEND_ACCOUNT_INFO,
                    element: <SendAccountInfoSubPage />
                  },
                  {
                    path: ROUTES.ROLES_ID_RESET_PASSWORD,
                    element: <ResetPasswordSubPage />
                  },
                  {
                    path: ROUTES.ROLES_ID_SEND_WELCOME_EMAIL,
                    element: <SendWelcomeSubPage />
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: ROUTES.SETTINGS,
        element: (
          <ProtectedRoute>
            <SettingsPage />
          </ProtectedRoute>
        )
      },
      {
        path: ROUTES.DATA_MODEL,
        element: <DataModelPage />
      },
      {
        path: ROUTES.CATCH_ALL,
        element: <NotFoundPage />
      }
    ]
  }
];

const router = createBrowserRouter(routeList, {
  basename: getApplicationBasePath()
});

export function Router() {
  return <RouterProvider router={router} />;
}
