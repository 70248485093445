import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, defaultTokens, Label, Switch, type ColorTokens } from '@knack/asterisk-react';
import { colord } from 'colord';
import cloneDeep from 'lodash/cloneDeep';

import { cn } from '@/utils/tailwind';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { BackgroundImage } from '@/pages/themes/theme-editor/BackgroundImage';
import {
  BORDERS,
  BRAND,
  SURFACE,
  TEXTS,
  type ThemeEditorToken
} from '@/pages/themes/theme-editor/tokens';
import { useThemesPageContext } from '@/pages/themes/ThemesPageContext';

function getNormalizedHexColor(color: string) {
  if (!color) {
    return '';
  }
  const newColor = colord(color).toHex();
  if (color.length >= 8) {
    return newColor.substring(0, newColor.length - 2);
  }
  return newColor;
}

export function ColorList({ tokenCollection }: { tokenCollection: ThemeEditorToken[] }) {
  const { theme, setThemeField } = useThemesPageContext();
  const columnClasses = 'flex flex-col p-2 gap-1 rounded-xl';
  const currentTokens = theme.colors.customTokens || defaultTokens;

  const composeColors = (currentMode: string) =>
    tokenCollection.map((token) => (
      <input
        key={`${currentMode}-${token.names[0].category}-${token.names[0].name}-${token.label}`}
        type="color"
        className="m-0 size-6 cursor-pointer bg-transparent p-0"
        value={getNormalizedHexColor(
          currentTokens[currentMode][token.names[0].category][token.names[0].name]
        )}
        onChange={(e) => {
          const newColors: ColorTokens = cloneDeep(currentTokens);
          token.names.forEach((name) => {
            newColors[currentMode][name.category][name.name] = getNormalizedHexColor(
              e.target.value
            );
          });
          setThemeField((draft) => {
            draft.colors.customTokens = newColors;
          });
        }}
      />
    ));

  return (
    <div className="flex">
      {(theme.mode === 'light' || theme.mode === 'system' || theme.shouldAllowModeToggle) && (
        <div className={columnClasses}>{composeColors('light')}</div>
      )}
      {(theme.mode === 'dark' || theme.mode === 'system' || theme.shouldAllowModeToggle) && (
        <div className={cn('bg-gray-950', columnClasses)}>{composeColors('dark')}</div>
      )}
      <div className={columnClasses}>
        {tokenCollection.map((token) => (
          <div key={token.label}>{token.label}</div>
        ))}
      </div>
    </div>
  );
}

export function CustomColors() {
  const [t] = useTranslation();
  const { theme, setThemeField } = useThemesPageContext();
  const [shouldUseGradient, setShouldUseGradient] = useState(
    !!theme.background.light || !!theme.background.dark
  );

  return (
    <div className="mt-6 flex flex-col gap-4">
      <BuilderAccordion>
        <BuilderAccordion.Item isDefaultOpen label={t('themes.custom_colors.brand')}>
          <ColorList tokenCollection={BRAND} />
        </BuilderAccordion.Item>
        <BuilderAccordion.Item isDefaultOpen label={t('themes.custom_colors.texts')}>
          <ColorList tokenCollection={TEXTS} />
        </BuilderAccordion.Item>
        <BuilderAccordion.Item isDefaultOpen label={t('themes.custom_colors.surface')}>
          <ColorList tokenCollection={SURFACE} />
          <div className="mt-4 flex items-center gap-2">
            <Switch
              id="use-custom-gradient"
              checked={shouldUseGradient}
              onCheckedChange={(value) => {
                if (!value) {
                  setThemeField((draft) => {
                    draft.background.light = '';
                    draft.background.dark = '';
                  });
                }
                setShouldUseGradient(value);
              }}
            />
            <Label htmlFor="use-custom-gradient" className="text-sm">
              {t('themes.use_custom_gradient')}
            </Label>
          </div>
          {shouldUseGradient && <BackgroundImage />}
        </BuilderAccordion.Item>
        <BuilderAccordion.Item isDefaultOpen label={t('themes.custom_colors.borders')}>
          <ColorList tokenCollection={BORDERS} />
        </BuilderAccordion.Item>
      </BuilderAccordion>
      <Button
        className="mt-4"
        intent="destructiveSecondary"
        onClick={() => {
          setThemeField((draft) => {
            draft.colors.customTokens = null;
            draft.background.light = '';
            draft.background.dark = '';
          });
        }}
      >
        {t('themes.reset_colors')}
      </Button>
    </div>
  );
}
