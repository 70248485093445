import i18n from 'i18next';

import { type KnackFieldKey, type KnackFieldType } from '@/types/schema/KnackField';
import { type ReportViewChartNumericGroupType } from '@/types/schema/views/ReportView';
import { NUMERIC_FIELD_TYPES } from '@/hooks/helpers/useFieldHelpers';

export type SortingOption = {
  label: string;
  value: 'asc' | 'desc';
};

export const sortingOptionsMap: Record<string, SortingOption[]> = {
  ...NUMERIC_FIELD_TYPES.reduce(
    (acc, type) => ({
      ...acc,
      [type]: [
        {
          label: i18n.t(
            'pages.element_settings.report.categories.data_display.sorting.numeric_asc_label'
          ),
          value: 'asc'
        },
        {
          label: i18n.t(
            'pages.element_settings.report.categories.data_display.sorting.numeric_desc_label'
          ),
          value: 'desc'
        }
      ]
    }),
    {}
  ),
  totals: [
    {
      label: i18n.t(
        'pages.element_settings.report.categories.data_display.sorting.numeric_asc_label'
      ),
      value: 'asc'
    },
    {
      label: i18n.t(
        'pages.element_settings.report.categories.data_display.sorting.numeric_desc_label'
      ),
      value: 'desc'
    }
  ],
  averages: [
    {
      label: i18n.t(
        'pages.element_settings.report.categories.data_display.sorting.numeric_asc_label'
      ),
      value: 'asc'
    },
    {
      label: i18n.t(
        'pages.element_settings.report.categories.data_display.sorting.numeric_desc_label'
      ),
      value: 'desc'
    }
  ],
  boolean: [
    {
      label: i18n.t(
        'pages.element_settings.report.categories.data_display.sorting.boolean_asc_label'
      ),
      value: 'asc'
    },
    {
      label: i18n.t(
        'pages.element_settings.report.categories.data_display.sorting.boolean_desc_label'
      ),
      value: 'desc'
    }
  ],
  date_time: [
    {
      label: i18n.t('pages.element_settings.report.categories.data_display.sorting.date_asc_label'),
      value: 'asc'
    },
    {
      label: i18n.t(
        'pages.element_settings.report.categories.data_display.sorting.date_desc_label'
      ),
      value: 'desc'
    }
  ],
  default: [
    {
      label: i18n.t(
        'pages.element_settings.report.categories.data_display.sorting.alpha_asc_label'
      ),
      value: 'asc'
    },
    {
      label: i18n.t(
        'pages.element_settings.report.categories.data_display.sorting.alpha_desc_label'
      ),
      value: 'desc'
    }
  ]
} as const;

export const getViewSettingsSortingOptions = (
  selectedFieldType?: KnackFieldType | ReportViewChartNumericGroupType
) => {
  if (!selectedFieldType) return sortingOptionsMap.default;

  return sortingOptionsMap[selectedFieldType] || sortingOptionsMap.default;
};

export const getViewSettingsSortingOptionLabel = (
  sortItem: {
    field: KnackFieldKey;
    order: 'asc' | 'desc';
  },
  fieldType: KnackFieldType
) => {
  const sortingOption: SortingOption[] =
    sortingOptionsMap[fieldType] || sortingOptionsMap.short_text;

  const selectedOption = sortingOption.find((option) => option.value === sortItem.order);

  return selectedOption?.label || '';
};
