import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@knack/asterisk-react';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type KnackOperator } from '@/types/schema/KnackOperator';
import { type RecordRule } from '@/types/schema/rules/RecordRule';
import { isDateTimeRangeOperator } from '@/utils/field-operators';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { BooleanFieldInput } from '@/components/filters/BooleanFieldInput';
import { ConnectionFieldInput } from '@/components/filters/ConnectionFieldInput';
import { MultipleChoiceFieldInput } from '@/components/filters/MultipleChoiceFieldInput';
import { DateTimeCriteriaInput } from '@/components/inputs/date-time/DateTimeCriteriaInput';
import { DateTimeRangeInput } from '@/components/inputs/date-time/DateTimeRangeInput';

interface ViewRecordRuleCriteriaValueProps {
  fieldKey: KnackFieldKey;
  sourceObject: KnackObject;
  selectedOperator: KnackOperator;
  index: number;
}

export function ViewRecordRuleCriteriaValue({
  fieldKey,
  sourceObject,
  selectedOperator,
  index
}: ViewRecordRuleCriteriaValueProps) {
  const [t] = useTranslation();

  const {
    register,
    formState: { errors }
  } = useFormContext<RecordRule>();

  const selectedField = sourceObject.fields.find((field) => field.key === fieldKey);

  if (!selectedField) {
    return null;
  }

  function getInputToRender() {
    if (!selectedField) {
      return null;
    }

    if (selectedField.type === 'connection') {
      return (
        <ConnectionFieldInput<RecordRule>
          field={selectedField}
          formFieldName={`criteria.${index}.value`}
        />
      );
    }

    if (selectedField.type === 'date_time') {
      if (isDateTimeRangeOperator(selectedOperator)) {
        return (
          <DateTimeRangeInput<RecordRule>
            rangeTypeFormFieldName={`criteria.${index}.type`}
            rangeValueFormFieldName={`criteria.${index}.range`}
            operator={selectedOperator}
          />
        );
      }
      return (
        <DateTimeCriteriaInput<RecordRule>
          field={selectedField}
          formFieldName={`criteria.${index}.value`}
        />
      );
    }

    if (selectedField.type === 'multiple_choice') {
      return (
        <MultipleChoiceFieldInput<RecordRule>
          field={selectedField}
          formFieldName={`criteria.${index}.value`}
        />
      );
    }

    if (selectedField.type === 'boolean') {
      return (
        <BooleanFieldInput<RecordRule>
          field={selectedField}
          formFieldName={`criteria.${index}.value`}
        />
      );
    }

    return (
      <Input
        data-testid={`criteria-${index}-value`}
        className="text-sm"
        intent={errors?.criteria?.[index]?.value ? 'destructive' : undefined}
        placeholder={t('actions.enter_value')}
        {...register(`criteria.${index}.value`)}
      />
    );
  }

  return (
    <>
      {getInputToRender()}
      <FormErrorMessage name={`criteria.${index}.value`} errors={errors} className="mt-2" />
    </>
  );
}
