import { Controller, useFormContext, type FieldArrayWithId, type FieldPath } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as AddIcon, HiXMark as RemoveIcon } from 'react-icons/hi2';
import { Button, Select } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import {
  type BuilderViewSourceCriteriaRule,
  type BuilderViewSourceSchemaCriteria
} from '@/types/schema/BuilderView';
import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackOperator } from '@/types/schema/KnackOperator';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { FieldIcon } from '@/components/FieldIcon';
import { FiltersInput } from '@/pages/pages/settings-panel/view-settings/common/FiltersInput';

interface SourceFilterDialogGroupItemProps {
  formFieldName: FieldPath<BuilderViewSourceSchemaCriteria>;
  ruleFieldIndex: number;
  ruleField: FieldArrayWithId<BuilderViewSourceSchemaCriteria, 'rules', 'id'>;
  availableFields: KnackField[];
  update: (index: number, value: BuilderViewSourceCriteriaRule) => void;
  append: (value: BuilderViewSourceCriteriaRule) => void;
  remove: (index: number) => void;
}

export function SourceFilterDialogGroupItem({
  formFieldName,
  ruleFieldIndex,
  ruleField,
  availableFields,
  update,
  remove,
  append
}: SourceFilterDialogGroupItemProps) {
  const [t] = useTranslation();
  const {
    watch,
    formState: { errors },
    getFieldState
  } = useFormContext<BuilderViewSourceSchemaCriteria>();

  const { getOperatorsForField } = useFieldHelpers();

  const currentOperator = watch(
    `${formFieldName}.operator` as FieldPath<BuilderViewSourceSchemaCriteria>
  ) as KnackOperator;

  const inputValueError = getFieldState(
    `${formFieldName}.value` as FieldPath<BuilderViewSourceSchemaCriteria>
  ).error;

  return (
    <div key={ruleField.id} className="flex gap-2">
      <div className="mt-2 min-w-10 text-right">
        <span>
          {ruleFieldIndex === 0 ? t('components.rules.where') : t('components.rules.and_uppercase')}
        </span>
      </div>
      <div className="w-full">
        <div className="flex">
          <div className="-m-1 flex-1 space-y-2 overflow-hidden p-1">
            <div className="flex gap-2">
              <div className="flex-1">
                <Controller
                  name={`${formFieldName}.field`}
                  render={({ field: { value: fieldKey, onChange } }) => (
                    <Select
                      value={fieldKey}
                      onValueChange={(newFieldKey: KnackFieldKey) => {
                        const defaultOperator = getOperatorsForField(
                          newFieldKey,
                          availableFields
                        )[0];

                        update(ruleFieldIndex, {
                          ...ruleField,
                          operator: defaultOperator,
                          value: ''
                        });
                        onChange(newFieldKey);
                      }}
                      disabled={availableFields.length === 0}
                    >
                      <Select.Trigger
                        placeholder={`${t('actions.select')}...`}
                        className={cn('w-full', {
                          'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                            errors[formFieldName]?.[ruleFieldIndex]?.field
                        })}
                        data-testid={`source-filter-dialog-field-${ruleFieldIndex}`}
                      />
                      <Select.Content>
                        {availableFields.map((field) => (
                          <Select.Item key={field.key} value={field.key}>
                            <span className="flex items-center">
                              <FieldIcon
                                className="mr-2 shrink-0 text-subtle"
                                size={16}
                                name={field.type}
                              />
                              {field.name}
                            </span>
                          </Select.Item>
                        ))}
                      </Select.Content>
                    </Select>
                  )}
                />
                <FormErrorMessage errors={errors} name={`${formFieldName}.field`} />
              </div>
              <div className="flex-1">
                <Controller
                  name={`${formFieldName}.operator`}
                  render={({ field: { value: operator, onChange } }) => (
                    <Select
                      value={operator}
                      onValueChange={(newOperator: KnackOperator) => {
                        onChange(newOperator);
                      }}
                    >
                      <Select.Trigger
                        placeholder={`${t('actions.select')}...`}
                        className="w-full"
                        data-testid={`source-filter-dialog-operator-${ruleFieldIndex}`}
                      />
                      <Select.Content>
                        {getOperatorsForField(ruleField.field, availableFields).map(
                          (fieldOperator: string) => (
                            <Select.Item key={fieldOperator} value={fieldOperator}>
                              {t(`operators.${snakeCase(fieldOperator)}`)}
                            </Select.Item>
                          )
                        )}
                      </Select.Content>
                    </Select>
                  )}
                />
              </div>
            </div>
            {!shouldHideValueBasedOnOperator(currentOperator) && (
              <>
                <FiltersInput<BuilderViewSourceSchemaCriteria>
                  error={inputValueError}
                  fieldKey={ruleField.field}
                  formFieldName={
                    `${formFieldName}.value` as FieldPath<BuilderViewSourceSchemaCriteria>
                  }
                />
                <FormErrorMessage errors={errors} name={`${formFieldName}.value`} />
              </>
            )}
          </div>
          <Button
            intent="minimal"
            aria-label={t('components.rules.delete_condition')}
            size="xs"
            className="ml-2 mt-1.5 text-subtle hover:bg-emphasis"
            onClick={() => remove(ruleFieldIndex)}
          >
            <RemoveIcon size={16} />
          </Button>
          <Button
            intent="minimal"
            aria-label={t('components.rules.delete_condition')}
            size="xs"
            className="ml-2 mt-1.5 text-subtle hover:bg-emphasis"
            onClick={() => {
              const defaultOperator = getOperatorsForField(
                availableFields[0].key,
                availableFields
              )[0];

              append({
                field: availableFields[0].key,
                operator: defaultOperator,
                value: ''
              });
            }}
          >
            <AddIcon size={16} />
          </Button>
        </div>
      </div>
    </div>
  );
}
