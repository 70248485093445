import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';

export function ReportDataDisplayOrder({
  defaultValue,
  onValueChange
}: {
  defaultValue: string;
  onValueChange: (value: string) => void;
}) {
  const [t] = useTranslation();

  return (
    <Select value={defaultValue} onValueChange={onValueChange}>
      <Select.Trigger
        id="report-data-display-order-select"
        placeholder={`${t('actions.select')}...`}
        className="w-full"
      />
      <Select.Content>
        <Select.Item value="sum">{t('keywords.sum')}</Select.Item>
        <Select.Item value="average">{t('keywords.average')}</Select.Item>
        <Select.Item value="min">{t('keywords.minimum')}</Select.Item>
        <Select.Item value="max">{t('keywords.maximum')}</Select.Item>
      </Select.Content>
    </Select>
  );
}
