/* eslint-disable no-console */
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi';
import { HiTrash as RemoveIcon } from 'react-icons/hi2';
import { Button, Input, Label } from '@knack/asterisk-react';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import type { ReportView, ReportViewCalculation } from '@/types/schema/views/ReportView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { FieldSelect } from '@/components/FieldSelect';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ReportDataDisplayOrder } from '@/pages/pages/settings-panel/view-settings/report/data-display/ReportDataDisplayOrder';
import { getBaseReportViewObject } from '@/pages/pages/settings-panel/view-settings/report/utils';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function ReportDataDisplayCalculation() {
  const [t] = useTranslation();
  const { view, sourceObject } = useActiveViewContext<ReportView>();
  const updateViewSchema = useUpdateView<ReportView>();
  const { groups, calculations } = view.rows[0].reports[0];

  // TODO: Add the correct values to the default calculation FE-3314
  const defaultCalculation: ReportViewCalculation = {
    calculation: 'sum',
    field: sourceObject.fields[0].key,
    label: ''
  };

  return (
    <BuilderAccordion.Item
      isDefaultOpen
      label={t('pages.element_settings.report.categories.data_display.y_axis')}
    >
      {calculations.map((calculation, index) => (
        <BuilderAccordion.Item
          key={calculation.label}
          isDefaultOpen
          label={t('pages.element_settings.report.categories.data_display.calculation_n', {
            count: index + 1
          })}
          className="mb-4 rounded-xl bg-subtle p-2"
          removeItemButton={
            calculations.length > 1 ? (
              <Button
                intent="minimal"
                onClick={() => {
                  const newSchema = getBaseReportViewObject(view);
                  newSchema.rows[0].reports[0].calculations.splice(index, 1);
                  updateViewSchema(newSchema);
                }}
              >
                <RemoveIcon className="text-subtle" />
              </Button>
            ) : undefined
          }
        >
          <div className="mb-4">
            <Label className="mb-2 block" htmlFor="calculation-label">
              {t('pages.element_settings.report.categories.data_display.label')}
            </Label>
            <Input
              id="calculation-label"
              value={calculation.label}
              className="text-sm"
              onChange={(e) => {
                const newSchema = getBaseReportViewObject(view);
                newSchema.rows[0].reports[0].calculations[index].label = e.target.value;
                updateViewSchema(newSchema);
              }}
            />
          </div>
          <div className="mb-4">
            <Label className="mb-2 block" htmlFor="calculation-field">
              {t('pages.element_settings.report.categories.data_display.field')}
            </Label>
            <FieldSelect
              id="calculation-field"
              fields={sourceObject?.fields}
              defaultValue={calculation.field}
              onFieldChange={(fieldSelected) => {
                const newSchema = getBaseReportViewObject(view);
                newSchema.rows[0].reports[0].calculations[index].field =
                  fieldSelected as KnackFieldKey;
                updateViewSchema(newSchema);
              }}
            />
            <div className="mb-2 mt-4">
              {t('pages.element_settings.report.categories.data_display.order')}
            </div>
            <ReportDataDisplayOrder
              defaultValue={calculation.calculation}
              onValueChange={(value) => {
                const newSchema = getBaseReportViewObject(view);
                newSchema.rows[0].reports[0].calculations[index].calculation =
                  value as ReportViewCalculation['calculation'];
                updateViewSchema(newSchema);
              }}
            />
          </div>
        </BuilderAccordion.Item>
      ))}
      <Button
        intent="secondary"
        disabled={groups.length > 1 || calculations.length > 1}
        onClick={() => {
          const newSchema = getBaseReportViewObject(view);
          newSchema.rows[0].reports[0].calculations.push(defaultCalculation);
          updateViewSchema(newSchema);
        }}
      >
        <PlusIcon size={16} className="mr-1" />
        {t('pages.element_settings.report.categories.data_display.y_axis_calculation')}
      </Button>
    </BuilderAccordion.Item>
  );
}
