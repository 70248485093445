import i18n from 'i18next';

import { type BuilderViewSourceSchema } from '@/types/schema/BuilderView';
import { type KnackFieldType } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import {
  type ChartGroupingCategory,
  type ReportView,
  type ReportViewChart,
  type ReportViewChartGroup,
  type ReportViewRow
} from '@/types/schema/views/ReportView';
import { NUMERIC_FIELD_TYPES } from '@/hooks/helpers/useFieldHelpers';
import { type ViewSource } from '@/pages/pages/page-editor/add-view/helpers/useViewSources';
import { generateNewViewKey } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/generateNewViewKey';

const baseReportViewSchema: Partial<ReportView> = {
  type: 'report',
  description: '',
  columns: [],
  inputs: [],
  links: []
};

export const NON_COMPATIBLE_FIELD_TYPES_FOR_REPORT_CATEGORIES: KnackFieldType[] = [
  'file',
  'image',
  'signature',
  'address',
  'phone',
  'email',
  'link',
  'paragraph_text',
  'rich_text',
  'user_roles',
  'password'
];

export function generateDefaultReportViewCategoryGroup(viewSource: ViewSource) {
  const firstAvailableCategoryField = viewSource.object.fields.find(
    (field) => !NON_COMPATIBLE_FIELD_TYPES_FOR_REPORT_CATEGORIES.includes(field.type)
  );

  if (!firstAvailableCategoryField) {
    return null;
  }

  const defaultReportViewCategoryGroup: ReportViewChartGroup = {
    group: `${firstAvailableCategoryField.type}-${firstAvailableCategoryField.key}`,
    type: firstAvailableCategoryField.type,
    field: firstAvailableCategoryField.key,
    label: firstAvailableCategoryField.name
  };

  return defaultReportViewCategoryGroup;
}

export function getGroupingCategoriesOptions(object: KnackObject) {
  const availableFieldsForGrouping = object.fields.filter(
    (field) => !NON_COMPATIBLE_FIELD_TYPES_FOR_REPORT_CATEGORIES.includes(field.type)
  );

  const hasNumericFields = availableFieldsForGrouping.some((field) =>
    NUMERIC_FIELD_TYPES.includes(field.type)
  );

  const fieldCategories: ChartGroupingCategory[] = availableFieldsForGrouping.map((field) => ({
    key: field.key,
    value: `${field.type}-${field.key}`,
    label: field.name,
    type: field.type
  }));

  if (hasNumericFields) {
    const fieldCategoriesWithNumericOptions: ChartGroupingCategory[] = [
      ...fieldCategories,
      {
        value: 'totals',
        label: i18n.t(
          'pages.element_settings.report.categories.data_display.totals_category_label'
        ),
        type: 'totals'
      },
      {
        value: 'averages',
        label: i18n.t(
          'pages.element_settings.report.categories.data_display.averages_category_label'
        ),
        type: 'averages'
      }
    ];

    return fieldCategoriesWithNumericOptions;
  }

  return fieldCategories;
}

function generateReportViewRows(viewSource: ViewSource, viewSubtype: ReportViewChart['type']) {
  const defaultCategoryGroup = generateDefaultReportViewCategoryGroup(viewSource);
  const firstAvailableCalculationField = viewSource.object.fields[0].key;

  const rows: ReportViewRow[] = [
    {
      reports: [
        {
          title: '',
          type: viewSubtype,
          source: {
            criteria: {
              match: 'all',
              rules: [],
              groups: []
            },
            limit: '',
            object: viewSource.object.key
          },
          filters: {
            filter_type: 'none',
            preset_filters: [
              // {
              //   value: '',
              //   operator: 'contains',
              //   field
              // }
            ],
            menu_filters: [
              // {
              //   key: '1',
              //   value: '',
              //   text: '',
              //   operator: 'contains',
              //   field
              // }
            ],
            filter_fields: 'view',
            allow_preset_filters: false
          },
          groups: defaultCategoryGroup ? [defaultCategoryGroup] : [],
          calculations: [
            {
              field: firstAvailableCalculationField,
              calculation: 'total',
              label: 'Total'
            }
          ],
          description: '',
          options: {
            exclude_empties: false,
            hide_negatives: false,
            child_records: false,
            export_links: false,
            shouldShowDataTable: false
          },
          layout: {
            dimensions: 'auto',
            chart_width: '500',
            chart_height: '350',
            legend_width: '170',
            legend: 'below',
            stacking: 'none',
            tilt_labels: false,
            data_labels: true,
            bar_type: 'bar'
          },
          preview: false,
          settings: {
            export_links: false,
            shouldShowDataTable: false
          }
        }
      ]
    }
  ];

  return rows;
}

export function getReportViewSchema(
  viewSource: ViewSource,
  viewSourceSchema: BuilderViewSourceSchema,
  viewSubtype: ReportViewChart['type']
) {
  const viewTitle =
    viewSource.recordDisplayQuantity === 'many'
      ? viewSource.object.inflections.plural
      : viewSource.object.inflections.singular;

  const reportViewSchema: Partial<ReportView> = {
    ...baseReportViewSchema,
    key: generateNewViewKey(),
    name: viewTitle,
    title: viewTitle,
    source: viewSourceSchema,
    rows: generateReportViewRows(viewSource, viewSubtype)
  };

  return reportViewSchema;
}
