import { useTranslation } from 'react-i18next';

import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { MainLayout } from '@/components/layout/MainLayout';

export function SettingsPage() {
  const [t] = useTranslation();

  return (
    <MainLayout>
      <div className="flex size-full w-80 shrink-0 flex-col border-r bg-muted">
        <CollapsiblePanel.Content
          title={t('components.layout.left_sidebar.settings')}
          shouldHideDivider
        >
          Coming soon...
        </CollapsiblePanel.Content>
      </div>
    </MainLayout>
  );
}
