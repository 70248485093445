import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';

// Expand this type as we need more settings properties to be updated
interface AppSettingsToUpdate {
  sso_google: {
    client_id: string;
    client_secret: string;
    domain_restriction: string;
  };
}

async function updateApplication(appSettingsToUpdate: AppSettingsToUpdate) {
  const { data } = await axios.put(
    '/v1/settings',
    {
      ...appSettingsToUpdate
    },
    {
      withCredentials: true
    }
  );

  return data;
}

export function useApplicationSettingsMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (appSettingsToUpdate: AppSettingsToUpdate) =>
      updateApplication(appSettingsToUpdate),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.application] });
    }
  });
}
